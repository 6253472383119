import { SvgIcon, type SvgIconProps } from '@mui/material'

const BasketIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.80065 2C1.54292 2 1.33398 2.20893 1.33398 2.46667C1.33398 2.7244 1.54292 2.93333 1.80065 2.93333H3.4263L5.25903 11.1806C4.61204 11.3471 4.13398 11.9344 4.13398 12.6333C4.13398 13.4618 4.80556 14.1333 5.63398 14.1333C6.46241 14.1333 7.13398 13.4618 7.13398 12.6333C7.13398 12.3805 7.07144 12.1423 6.96098 11.9333H10.9737C10.8632 12.1423 10.8007 12.3805 10.8007 12.6333C10.8007 13.4618 11.4722 14.1333 12.3007 14.1333C13.1291 14.1333 13.8007 13.4618 13.8007 12.6333C13.8007 12.0105 13.421 11.4763 12.8805 11.2495C12.8024 11.1012 12.6466 11 12.4673 11H6.175L5.86389 9.6H12.8007C13.0207 9.6 13.2109 9.44628 13.257 9.23111L14.257 4.56445C14.2865 4.42665 14.2522 4.28288 14.1636 4.17328C14.075 4.06369 13.9416 4 13.8007 4H4.61944L4.25621 2.36543C4.20876 2.15191 4.01938 2 3.80065 2H1.80065ZM4.82685 4.93333L5.65648 8.66667H12.4234L13.2234 4.93333H4.82685ZM5.63398 13.2C5.94695 13.2 6.20065 12.9463 6.20065 12.6333C6.20065 12.3204 5.94695 12.0667 5.63398 12.0667C5.32102 12.0667 5.06732 12.3204 5.06732 12.6333C5.06732 12.9463 5.32102 13.2 5.63398 13.2ZM12.8673 12.6333C12.8673 12.9463 12.6136 13.2 12.3007 13.2C11.9877 13.2 11.734 12.9463 11.734 12.6333C11.734 12.3204 11.9877 12.0667 12.3007 12.0667C12.6136 12.0667 12.8673 12.3204 12.8673 12.6333Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export default BasketIcon
