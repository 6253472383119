import { useMemo } from 'react'

import { type PaginationProps, useMediaQuery, useTheme } from '@mui/material'

import { StyledPagination } from './Pagination.styled'

const Pagination = (props: PaginationProps) => {
  const theme = useTheme()

  const isLg = useMediaQuery(theme.breakpoints.up('lg'))

  const isMd = useMediaQuery(theme.breakpoints.up('md'))

  const isSm = useMediaQuery(theme.breakpoints.up('sm'))

  const isXs = useMediaQuery(theme.breakpoints.up('xs'))

  const siblingCount = useMemo(() => {
    if (isLg) {
      return 2
    }

    if (isMd) {
      return 1
    }

    if (isSm || isXs) {
      return 0
    }
  }, [isLg, isMd, isSm, isXs])

  const size = useMemo(() => {
    if (isLg) {
      return 'large'
    }

    if (isMd || isSm) {
      return 'medium'
    }

    if (isXs) {
      return 'small'
    }
  }, [isLg, isMd, isSm, isXs])

  return <StyledPagination size={size} siblingCount={siblingCount} {...props} />
}

export default Pagination
