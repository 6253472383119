import { SvgIcon, type SvgIconProps } from '@mui/material'

const NotificationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M5.58545 19C5.30212 19 5.06462 18.9042 4.87295 18.7125C4.68128 18.5208 4.58545 18.2833 4.58545 18C4.58545 17.7167 4.68128 17.4792 4.87295 17.2875C5.06462 17.0958 5.30212 17 5.58545 17H6.58545V10C6.58545 8.61667 7.00212 7.3875 7.83545 6.3125C8.66878 5.2375 9.75212 4.53333 11.0854 4.2V3.5C11.0854 3.08333 11.2313 2.72917 11.5229 2.4375C11.8146 2.14583 12.1688 2 12.5854 2C13.0021 2 13.3563 2.14583 13.6479 2.4375C13.9396 2.72917 14.0854 3.08333 14.0854 3.5V4.2C15.4188 4.53333 16.5021 5.2375 17.3354 6.3125C18.1688 7.3875 18.5854 8.61667 18.5854 10V17H19.5854C19.8688 17 20.1063 17.0958 20.2979 17.2875C20.4896 17.4792 20.5854 17.7167 20.5854 18C20.5854 18.2833 20.4896 18.5208 20.2979 18.7125C20.1063 18.9042 19.8688 19 19.5854 19H5.58545ZM12.5854 22C12.0354 22 11.5646 21.8042 11.1729 21.4125C10.7813 21.0208 10.5854 20.55 10.5854 20H14.5854C14.5854 20.55 14.3896 21.0208 13.9979 21.4125C13.6063 21.8042 13.1354 22 12.5854 22ZM8.58545 17H16.5854V10C16.5854 8.9 16.1938 7.95833 15.4104 7.175C14.6271 6.39167 13.6854 6 12.5854 6C11.4854 6 10.5438 6.39167 9.76045 7.175C8.97712 7.95833 8.58545 8.9 8.58545 10V17Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export default NotificationIcon
