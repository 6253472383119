import { Box, Card, CardMedia, styled, Typography } from '@mui/material'

export const StyledCard = styled(Card)`
  background-color: transparent;
  cursor: pointer;
  border-radius: 16px;
`

export const StyledTypography = styled(Typography)`
  cursor: pointer;
`

export const StyledCardMediaContainer = styled('div')`
  position: relative;
`

export const StyledCardMedia = styled(CardMedia)`
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  object-fit: contain;
`

export const StyledBadgeContainer = styled(Box)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 4px;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 8px;
  padding-right: 8px;

  @media (min-width: 600px) {
    padding-top: 16px;
    padding-right: 16px;
    gap: 8px;
  }
`

export const StyledBadgeStack = styled('div')`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 4px;

  @media (min-width: 600px) {
    gap: 8px;
  }
`
