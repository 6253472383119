import { SvgIcon, type SvgIconProps } from '@mui/material'

const DeleteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M4.66699 14C4.30033 14 3.98644 13.8694 3.72533 13.6083C3.46421 13.3472 3.33366 13.0333 3.33366 12.6667V4C3.14477 4 2.98644 3.93611 2.85866 3.80833C2.73088 3.68056 2.66699 3.52222 2.66699 3.33333C2.66699 3.14444 2.73088 2.98611 2.85866 2.85833C2.98644 2.73056 3.14477 2.66667 3.33366 2.66667H6.00033C6.00033 2.47778 6.06421 2.31944 6.19199 2.19167C6.31977 2.06389 6.4781 2 6.66699 2H9.33366C9.52255 2 9.68088 2.06389 9.80866 2.19167C9.93644 2.31944 10.0003 2.47778 10.0003 2.66667H12.667C12.8559 2.66667 13.0142 2.73056 13.142 2.85833C13.2698 2.98611 13.3337 3.14444 13.3337 3.33333C13.3337 3.52222 13.2698 3.68056 13.142 3.80833C13.0142 3.93611 12.8559 4 12.667 4V12.6667C12.667 13.0333 12.5364 13.3472 12.2753 13.6083C12.0142 13.8694 11.7003 14 11.3337 14H4.66699ZM11.3337 4H4.66699V12.6667H11.3337V4ZM6.66699 11.3333C6.85588 11.3333 7.01421 11.2694 7.14199 11.1417C7.26977 11.0139 7.33366 10.8556 7.33366 10.6667V6C7.33366 5.81111 7.26977 5.65278 7.14199 5.525C7.01421 5.39722 6.85588 5.33333 6.66699 5.33333C6.4781 5.33333 6.31977 5.39722 6.19199 5.525C6.06421 5.65278 6.00033 5.81111 6.00033 6V10.6667C6.00033 10.8556 6.06421 11.0139 6.19199 11.1417C6.31977 11.2694 6.4781 11.3333 6.66699 11.3333ZM9.33366 11.3333C9.52255 11.3333 9.68088 11.2694 9.80866 11.1417C9.93644 11.0139 10.0003 10.8556 10.0003 10.6667V6C10.0003 5.81111 9.93644 5.65278 9.80866 5.525C9.68088 5.39722 9.52255 5.33333 9.33366 5.33333C9.14477 5.33333 8.98644 5.39722 8.85866 5.525C8.73088 5.65278 8.66699 5.81111 8.66699 6V10.6667C8.66699 10.8556 8.73088 11.0139 8.85866 11.1417C8.98644 11.2694 9.14477 11.3333 9.33366 11.3333Z"
        fill="#626262"
      />
    </SvgIcon>
  )
}

export default DeleteIcon
