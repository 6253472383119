import { DateTime } from 'luxon'

import { useCallback } from 'react'

import { Stack, Tooltip } from '@mui/material'

import { type IInstantPrizeCardProps } from './InstantPrizeCard.types'
import {
  StyledCard,
  StyledPrizeNameTypography,
  StyledThumbnailPicture,
  StyledWinnerTitle,
  StyledWinningTicketBadge,
  StyledWinningDateBadge,
  StyledWinningCreditDateBadge
} from './InstantPrizeCard.styled'

const InstantPrizeCard = (props: IInstantPrizeCardProps) => {
  const { prize, isAccountPage = false } = props

  const renderWinnerSection = useCallback(() => {
    return (
      <>
        {isAccountPage && (
          <Stack spacing={1} direction="column" alignItems="flex-start">
            <StyledWinnerTitle fontVariant="body-3" weight="regular">
              Winning day
            </StyledWinnerTitle>

            <StyledWinningDateBadge fontVariant="body-3" weight="medium">
              {DateTime.fromISO(prize.wonAt).toLocaleString(
                DateTime.DATETIME_SHORT
              )}
            </StyledWinningDateBadge>
          </Stack>
        )}

        {prize.creditAlternativeAt && (
          <Stack spacing={1} direction="column" alignItems="flex-start">
            <StyledWinnerTitle fontVariant="body-3" weight="regular">
              Wallet credited
            </StyledWinnerTitle>

            <StyledWinningCreditDateBadge fontVariant="body-3" weight="medium">
              {DateTime.fromISO(prize.creditAlternativeAt).toLocaleString(
                DateTime.DATETIME_SHORT
              )}
            </StyledWinningCreditDateBadge>
          </Stack>
        )}

        <Stack spacing={1} direction="column" alignItems="flex-start">
          <StyledWinnerTitle fontVariant="body-3" weight="regular">
            Winning ticket
          </StyledWinnerTitle>

          <StyledWinningTicketBadge fontVariant="body-3" weight="medium">
            {prize.ticketLabel}
          </StyledWinningTicketBadge>
        </Stack>
      </>
    )
  }, [prize, isAccountPage])

  return (
    <StyledCard
      borderRadius={{
        sm: '16px',
        xs: '8px'
      }}
      width="100%"
      height="100%"
      overflow="hidden"
    >
      <Stack
        spacing={{
          lg: 0,
          md: 1,
          sm: 1,
          xs: 1
        }}
        direction={{
          lg: 'row',
          md: 'column',
          sm: 'column',
          xs: 'column'
        }}
        width="100%"
        height="100%"
        overflow="hidden"
      >
        <Stack
          direction={
            isAccountPage
              ? 'row'
              : {
                  lg: 'row',
                  xs: 'column'
                }
          }
          justifyContent="space-between"
          alignItems={
            isAccountPage
              ? 'center'
              : {
                  lg: 'center',
                  xs: 'flex-start'
                }
          }
          spacing={1}
          width="100%"
          overflow="hidden"
        >
          <StyledThumbnailPicture
            cdnImagesObj={prize.image.images}
            alt={prize.image.altText}
            loading="eager"
            preferredSize="small"
          />

          <Stack
            direction="column"
            justifyContent="flex-start"
            spacing={1}
            width="100%"
            overflow="hidden"
          >
            <Tooltip title={prize.name}>
              <StyledPrizeNameTypography
                fontVariant="heading-4"
                weight="semibold"
              >
                {prize.name}
              </StyledPrizeNameTypography>
            </Tooltip>

            <Stack
              direction="row"
              spacing={2}
              display={{
                lg: 'flex',
                md: 'none',
                sm: 'none',
                xs: 'none'
              }}
            >
              {renderWinnerSection()}
            </Stack>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          flexWrap="wrap"
          gap={1}
          display={{
            lg: 'none',
            md: 'flex',
            sm: 'flex',
            xs: 'flex'
          }}
        >
          {renderWinnerSection()}
        </Stack>
      </Stack>
    </StyledCard>
  )
}

export default InstantPrizeCard
