import { css, styled } from '@mui/material'

import { INoticeProps } from './Notice.types'

export const StyledNotice = styled('div', {
  shouldForwardProp: propName => propName !== 'variant'
})<INoticeProps>`
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 16px;
  margin-bottom: 16px;
  flex-grow: 1;
  flex-basis: auto;

  ${({ variant = 'default' }) => {
    if (variant === 'default') {
      return css`
        border: 1px solid rgba(255, 255, 255, 0.45);
        background: linear-gradient(95deg, #44324e -9.96%, #000055 99.16%);
        color: var(--Neutral1);
      `
    }
  }}
`
