import { SvgIcon, type SvgIconProps } from '@mui/material'

const PlusIcon = (props: SvgIconProps) => {
  const { fill = '#c7c7cb', ...otherProps } = props

  return (
    <SvgIcon {...otherProps}>
      <path
        d="M7.33334 8.66665H4.00001C3.81112 8.66665 3.65279 8.60276 3.52501 8.47498C3.39723 8.3472 3.33334 8.18887 3.33334 7.99998C3.33334 7.81109 3.39723 7.65276 3.52501 7.52498C3.65279 7.3972 3.81112 7.33331 4.00001 7.33331H7.33334V3.99998C7.33334 3.81109 7.39723 3.65276 7.52501 3.52498C7.65279 3.3972 7.81112 3.33331 8.00001 3.33331C8.1889 3.33331 8.34723 3.3972 8.47501 3.52498C8.60279 3.65276 8.66668 3.81109 8.66668 3.99998V7.33331H12C12.1889 7.33331 12.3472 7.3972 12.475 7.52498C12.6028 7.65276 12.6667 7.81109 12.6667 7.99998C12.6667 8.18887 12.6028 8.3472 12.475 8.47498C12.3472 8.60276 12.1889 8.66665 12 8.66665H8.66668V12C8.66668 12.1889 8.60279 12.3472 8.47501 12.475C8.34723 12.6028 8.1889 12.6666 8.00001 12.6666C7.81112 12.6666 7.65279 12.6028 7.52501 12.475C7.39723 12.3472 7.33334 12.1889 7.33334 12V8.66665Z"
        fill={fill}
      />
    </SvgIcon>
  )
}

export default PlusIcon
