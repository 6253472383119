export enum KlaviyoEventEnum {
  VIEWED_PRODUCT = 'Viewed Product',
  ADDED_TO_CART = 'Added to Cart'
}

export enum HotJarEventEnum {
  IDENTIFY = 'identify'
}

export enum CurrencyTypeEnum {
  GBP = 'GBP'
}

export enum ColorModeTypeEnum {
  LIGHT = 'light',
  DARK = 'dark'
}

export enum CardTypeEnum {
  JCB = 'jcb',
  DINERSCLUB = 'dinersclub',
  AMEX = 'amex',
  MASTERCARD = 'mastercard',
  VISA = 'visa'
}

export enum WebsiteContentsEnum {
  HOW_TO_PLAY = 'HOW_TO_PLAY',
  ABOUT_US = 'ABOUT_US',
  FAQ = 'FAQ',
  TNC = 'TNC',
  BLOGS = 'BLOGS',
  HOME_PAGE = 'HOME',
  ACTIVE_COMPETITIONS = 'ACTIVE_COMPETITIONS',
  LIVE_DRAWS = 'LIVE_DRAWS',
  WINNERS_PODIUM = 'WINNERS_PODIUM',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  HOW_IT_WORKS = 'HOW_IT_WORKS',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  BANNER_CONTENT = 'BANNER_TEXT',
  PAST_COMPETITIONS = 'PAST_COMPETITIONS',
  COMPETITION_CATEGORIES = 'COMPETITION_CATEGORIES',
  DOWNLOAD_APP = 'DOWNLOAD_APP',
  SOCIAL_STATS = 'SOCIAL_STATS',
  INSTANT_WINNERS = 'INSTANT_WINNERS'
}

export enum IntentEnum {
  RECHECK = 'recheck'
}

export enum PaymentStatusEnum {
  FAILED = 'FAILED',
  AUTHORIZED = 'AUTHORIZED',
  CAPTURED = 'CAPTURED'
}

export enum CompetitionCategorySlugEnum {
  CAR_COMPETITIONS = 'car-competitions',
  CASH_COMPETITIONS = 'cash-competitions',
  WATCH_COMPETITIONS = 'watch-competitions'
}

export enum QueryClientKeysEnum {
  GET_CART_PRICE = 'GET_CART_PRICE',
  GET_APPLICATION_CONFIG = 'GET_APPLICATION_CONFIG',
  GET_COMPETITION_WINNER = 'GET_COMPETITION_WINNER',
  GET_PROFILE = 'GET_PROFILE',
  GET_INFLUENCER_REQUEST_STATUS = 'GET_INFLUENCER_REQUEST_STATUS',
  GET_KLAVIYO_SUBSCRIPTION_STATUS = 'GET_KLAVIYO_SUBSCRIPTION_STATUS',
  GET_LIVE_DRAWS = 'GET_LIVE_DRAWS',
  GET_INSTANT_PRIZES = 'GET_INSTANT_PRIZES',
  CALCULATE_ALTERNATIVE_CREDIT = 'CALCULATE_ALTERNATIVE_CREDIT',
  GET_CATEGORIES = 'GET_CATEGORIES',
  GET_PROMOTION_BANNER_TEXT = 'GET_PROMOTION_BANNER_TEXT',
  GET_COMPETITIONS = 'GET_COMPETITIONS',
  GET_WINNERS = 'GET_WINNERS',
  GET_TICKETS = 'GET_TICKETS',
  GET_PAST_COMPETITIONS = 'GET_PAST_COMPETITIONS',
  GET_COMPETITION_INSTANT_PRIZES = 'GET_COMPETITION_INSTANT_PRIZES',
  GET_COMPETITION_INSTANT_PRIZES_GROUPS = 'GET_COMPETITION_INSTANT_PRIZES_GROUPS',
  GET_INSTANT_PRIZE_GROUP_WINNERS = 'GET_INSTANT_PRIZE_GROUP_WINNERS',
  GET_TESTIMONIALS = 'GET_TESTIMONIALS',
  GET_COMPETITION_DETAILS = 'GET_COMPETITION_DETAILS',
  GET_USER_LAST_TICKET = 'GET_USER_LAST_TICKET',
  GET_HOMEPAGE_CONTENT = 'GET_HOMEPAGE_CONTENT',
  GET_SOCIAL_STATS_CONTENT = 'GET_SOCIAL_STATS_CONTENT'
}

export enum TicketAvailabilityStatusEnum {
  AVAILABLE = 0,
  NOT_AVAILABLE = 1,
  ERROR = 2,
  UNKNOWN = 3
}

export enum InstaWinStatusEnum {
  LOADING_CART_ITEMS = 'LOADING_CART_ITEMS',
  CHECKING_TICKETS = 'CHECKING_TICKETS',
  WON = 'WON',
  NOT_WON = 'NOT_WON',
  NOT_FOUND = 'NOT_FOUND'
}

export enum CKOScriptStateEnum {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  ERROR = 'ERROR'
}

export enum PerPageItemsEnum {
  VALUE_6 = 6,
  VALUE_9 = 9,
  VALUE_10 = 10,
  VALUE_15 = 15,
  VALUE_20 = 20,
  VALUE_25 = 25,
  VALUE_50 = 50,
  VALUE_100 = 100
}
