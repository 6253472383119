import { forwardRef } from 'react'

import { StyledBadge } from './Badge.styled'
import { IBadgeProps } from './Badge.types'

const Badge = forwardRef<HTMLDivElement, IBadgeProps>((props, ref) => {
  const {
    className,
    variant = 'price',
    layoutMode = 'fixed',
    ...otherProps
  } = props

  return (
    <StyledBadge
      layoutMode={layoutMode}
      variant={variant}
      className={className}
      ref={ref}
      {...otherProps}
    />
  )
})

export default Badge
