import { forwardRef } from 'react'

import { StyledNotice } from './Notice.styled'
import { INoticeProps } from './Notice.types'

const Notice = forwardRef<HTMLDivElement, INoticeProps>((props, ref) => {
  const { className, variant = 'default', ...otherProps } = props

  return (
    <StyledNotice
      variant={variant}
      className={className}
      ref={ref}
      {...otherProps}
    />
  )
})

export default Notice
