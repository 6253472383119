import React from 'react'

import { observable, action, configure, reaction } from 'mobx'

const Wrapper = ({ message }) => {
  return message
}

configure({
  enforceActions: 'observed'
})

export class NotificationStore {
  @observable toastId = null
  @observable message = ''
  @observable type = ''
  @observable notificationCount = 0

  constructor() {
    reaction(
      () => {
        if (this.message !== '' && this.type !== '') {
          return {
            type: this.type,
            message: this.message
          }
        }
      },
      toastConfig => {
        if (toastConfig) {
          this.notifyReaction(toastConfig)
        }
      }
    )

    if (document.readyState === 'complete') {
      this.loadToastify()
    } else {
      window.addEventListener('load', this.loadToastify.bind(this))
    }
  }

  toast = null

  async loadToastify() {
    this.toast = (await import('react-toastify')).toast
  }

  notifyReaction({ type, message }) {
    if (this.toast && !this.toast.isActive(this.toastId)) {
      const fn = ['success', 'error', 'info', 'warn'].includes(type)
        ? type
        : 'success'

      this.toastId = this.toast[fn](<Wrapper message={message} />, {
        onClose: action(() => {
          this.message = ''
          this.type = ''
        })
      })
    }
  }

  @action
  notify({ message, type }) {
    this.message = message
    this.type = type
  }

  @action
  setNotificationCount(count) {
    if (count < 0) count = 0
    this.notificationCount = count
  }
}

let notificationStore

export function notificationStoreFactory() {
  if (!process.browser) {
    notificationStore = {}
  }

  if (process.browser && !notificationStore) {
    window.notificationStore = notificationStore = new NotificationStore()
  }

  return notificationStore
}

export default notificationStore
